<template>
  <ion-card>
    <ion-item lines="none">
      <ion-card-content class="person-card" v-if="!showPhone">
        <div v-if="person">
          <ion-label>
            <ion-text color="primary">{{
              person.Initialer.toUpperCase()
            }}</ion-text>
          </ion-label>
          <ion-label class="person-name"
            >{{ person.Navn }} {{ person.Efternavn }}</ion-label
          >
          <div class="call-person" @click="showPhone = true">
            <img src="../../../public/assets/icon/call.png" alt="" />
          </div>
        </div>
        <div v-else>
          <ion-label>Normal drift</ion-label>
        </div>
      </ion-card-content>
      <ion-card-content class="person-card" v-if="person && showPhone">
        <ion-text class="text-align-center"
          >Ring til {{ person.Initialer }}</ion-text
        >
        <ion-grid>
          <ion-row>
            <ion-col v-if="person.MortalinMobil">
              <ion-item :href="'tel:' + person.MortalinMobil">
                <ion-icon
                  :icon="icons.logo"
                  size="large"
                  slot="start"
                ></ion-icon>
                <ion-label>
                  <h2>Arbejde</h2>
                  <p>
                    <b>{{ format(person.MortalinMobil) }}</b>
                  </p>
                </ion-label>
              </ion-item>
            </ion-col>
            <ion-col v-if="person.MobilArbejde">
              <ion-item :href="'tel:' + person.MobilArbejde">
                <ion-icon
                  :icon="icons.phone"
                  size="large"
                  slot="start"
                ></ion-icon>
                <ion-label>
                  <h2>Arbejde mobil</h2>
                  <p>
                    <b>{{ format(person.MobilArbejde) }}</b>
                  </p>
                </ion-label>
              </ion-item>
            </ion-col>
            <ion-col v-if="person.PrivatMobil">
              <ion-item :href="'tel:' + person.PrivatMobil">
                <ion-icon
                  :icon="icons.mobile"
                  size="large"
                  slot="start"
                ></ion-icon>
                <ion-label>
                  <h2>Privat mobil</h2>
                  <p>
                    <b>{{ format(person.PrivatMobil) }}</b>
                  </p>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-icon
          :icon="icons.close"
          slot="start"
          class="info-icon close-icon"
          @click="showPhone = false"
        ></ion-icon>
      </ion-card-content>
    </ion-item>
  </ion-card>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonText,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

import { close, phonePortraitSharp, callSharp } from "ionicons/icons";

export default {
  props: ["person"],
  components: {
    IonItem,
    IonLabel,
    IonCard,
    IonCardContent,
    IonText,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      showPhone: false,
      icons: {
        close: close,
        phone: callSharp,
        mobile: phonePortraitSharp,
        logo: require("../../../public/assets/mortalin.svg"),
      },
    };
  },
  methods: {
    format(s) {
      return s.toString().replace(/\d{4}(?=.)/g, "$& ");
    },
  },
};
</script>
