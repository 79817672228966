<template>
  <base-layout show-back-btn="true" page-default-back-link="/" show-info="true">
    <ion-text color="primary" class="ion-text-center title-headline">
      <h1>Vagtskema</h1>
      <ion-text color="secondary">
        <h3>{{ dayName }} {{ dateString }}</h3>
      </ion-text>
    </ion-text>

    <ion-list v-if="dateString" class="work-list">
      <template
        v-for="(assignedPerson, region) in sortedWorkPlanRegions"
        :key="region"
      >
        <ion-item
          button="true"
          :color="getItemColor(region)"
          @click="setActive(region)"
        >
          <ion-label>{{ region }}</ion-label>
          <ion-icon
            :icon="region == isActive ? icons.up : icons.down"
          ></ion-icon>
        </ion-item>
        <work-plan-person
          v-if="region == isActive"
          :person="getPerson(assignedPerson)"
        ></work-plan-person>
      </template>
    </ion-list>
  </base-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { callSharp, chevronDown, chevronUp } from "ionicons/icons";
import WorkPlanPerson from "../components/app/WorkPlanPerson";

import { IonItem, IonLabel, IonIcon, IonText, IonList } from "@ionic/vue";

export default {
  name: "Work",
  data() {
    return {
      dayName: null,
      dateString: null,
      workPlanDay: null,
      isActive: null,
      icons: {
        phone: callSharp,
        down: chevronDown,
        up: chevronUp,
      },
      days: [
        "Søndag",
        "Mandag",
        "Tirsdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "Lørdag",
      ],
    };
  },
  components: {
    IonItem,
    IonLabel,
    IonIcon,
    IonText,
    IonList,
    WorkPlanPerson,
  },
  computed: {
    ...mapGetters(["workPlanData", "data"]),
    sortedWorkPlanRegions() {
      if (!this.workPlanDay) return {};
      const { Dato, ...regions } = this.workPlanDay;

      const entries = Object.entries(regions);
      return Object.fromEntries(
        entries.sort(([keyA], [keyB]) => {
          if (keyA === "Serviceleder") return -1;
          if (keyB === "Serviceleder") return 1;
          return keyA.localeCompare(keyB);
        })
      );
    },
  },
  mounted() {
    this.getDate();
  },
  methods: {
    getItemColor(region) {
      return region === "Serviceleder" ? "serviceleder" : "secondary";
    },
    setActive(key) {
      if (this.isActive == key) {
        this.isActive = null;
        return;
      }
      this.isActive = key;
    },
    getDate() {
      // Create date and adjust for 8-hour offset
      const currentDate = new Date();
      const adjustedDate = new Date(
        currentDate.setHours(currentDate.getHours() - 8)
      );

      // Set day name in Danish
      this.dayName = this.days[adjustedDate.getDay()];

      // Format date as DD/MM/YYYY
      this.dateString = adjustedDate.toLocaleDateString("en-GB");

      this.workPlanDay = this.workPlanData.find(
        (x) => x.Dato === this.dateString
      );
    },
    getPerson(ini) {
      if (ini) {
        var person = this.data.find(
          (x) => x.Initialer.toUpperCase() == ini.toUpperCase()
        );
        return person ? person : null;
      }
      return null;
    },
  },
};
</script>
